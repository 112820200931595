import React, { useState, useEffect } from 'react';

const FetchAPI = {
  getData: async (page, pageSize, searchQuery) => {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/donations/one-time-event-donation-history?page=${page}&pageSize=${pageSize}&searchQuery=${searchQuery}`);
    const data = await response.json();
    return data.donations;
  }
};

function App() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const pageSize = 10; // Number of items per page

  const fetchData = async () => {
    const response = await FetchAPI.getData(currentPage, pageSize, searchTerm);
    setData(response.data);
    setLastPage(response.totalPages)
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const formatDateTime = (dateTimeString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    const formattedDate = new Date(dateTimeString).toLocaleDateString('en-GB', options);
    return formattedDate;
  };

  return (
    <div className="max-w-screen-lg mx-auto p-4">
      <input
        type="text"
        placeholder="Search by name"
        value={searchTerm}
        onChange={handleSearch}
        className="p-2 mb-4 rounded border"
      />
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">Email</th>
            <th className="py-2 px-4 border-b">Phone</th>
            <th className="py-2 px-4 border-b">Donation Amount</th>
            <th className="py-2 px-4 border-b">Time</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.id}>
              <td className="py-2 px-4 border-b">{item.name}</td>
              <td className="py-2 px-4 border-b">{item.email}</td>
              <td className="py-2 px-4 border-b">{item.phone}</td>
              <td className="py-2 px-4 border-b">{item.amount/100}</td>
              <td className="py-2 px-4 border-b">{formatDateTime(item.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4">
        <button className="px-4 py-2 mr-2 bg-blue-500 text-white rounded" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
        <span> Page {currentPage} </span>
        {lastPage > currentPage ? (<button className="px-4 py-2 mr-2 bg-blue-500 text-white rounded" onClick={() => setCurrentPage(currentPage + 1)}>Next</button>) : null}
        
      </div>
    </div>
  );
}

export default App;
